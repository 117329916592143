import '../css/global.css';
import $ from 'jquery';
import '@fortawesome/fontawesome-free';
import 'bootstrap';
import 'nouislider';
import 'owl.carousel';
import 'select2';
import 'select2/dist/js/i18n/it.js';
import 'lazysizes';
import toastr from 'toastr';
import {
  CustomSwal
} from './utils';
import {
  sendGtagConfig
} from './analytics';

import './number';
import './main';

import './vue-components/vue-components.js';
import trans from './i18n';
import { trackFormSubmit } from './tag-manager/gtm';

jQuery(document).ready(() => {
  jQuery('.flash-modal').modal({
    backdrop: 'static',
  });

  $('.request-confirmation').on('click', function () {
    const title = $(this).data('title');
    const text = $(this).data('text');
    const url = $(this).data('url');
    const confirmButton = $(this).attr('data-confirmText');
    const cancelButton = $(this).attr('data-cancelText');

    CustomSwal({
      title: title || trans('js-files.conferma-richiesta'),
      text: text || trans('js-files.sicuri-procedere'),
      confirmButtonText: confirmButton || trans('js-files.si-elimina'),
      cancelButtonText: cancelButton || trans('js-files.no-annulla'),
      showCancelButton: true,
    }).then(function (result) {
      if (result.value) {
        location.href = url;
      }
    });
  });

  $('.showhide.btn.btn-black.btn-lg.collapsed')
    .text(trans('common.mostra-ricerca'))
    .click(function () {
      if ($(this).text() == trans('common.mostra-ricerca')) {
        $(this).text(trans('common.nascondi-ricerca'));
      } else {
        $(this).text(trans('common.mostra-ricerca'))
      }
      // $(this).text('Nascondi');
    });
    $('.showhide.btn.btn-primary.btn-lg.collapsed')
    .text(trans('common.mostra-ricerca'))
    .click(function () {
      if ($(this).text() == trans('common.mostra-ricerca')) {
        $(this).text(trans('common.nascondi-ricerca'));
      } else {
        $(this).text(trans('common.mostra-ricerca'))
      }
      // $(this).text('Nascondi');
    });
});



(function () {
  'use strict';
  window.addEventListener(
    'load',
    function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.getElementsByClassName('custom-validation');
      // Loop over them and prevent submission
      Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          'submit',
          function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            } else {
              const submitInput = form.querySelector('button[type="submit"]');
              if (submitInput) {
                submitInput.classList.add('btn-loading');
              }
            }
            form.classList.add('was-validated');
          },
          false
        );
      });
    },
    false
  );
})();

window.toastr = toastr;
window.CustomSwal = CustomSwal;

window.initGtag = function (config, configId) {
  sendGtagConfig(config, configId);
};

window.trackFormSubmit = trackFormSubmit;

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// window.traskContactSedePage = () => {
//   const sede = JSON.parse(document.querySelector('meta[name="sede"]').content);
//   window.trackFormSubmit('contattaci-sede-page', window.location.href);
//   console.log(sede, document.querySelector('meta[name="sede"]'));
//   // return false;
// }
