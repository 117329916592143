<template>
  <div>
    <div class="d-flex justify-content-end form-group align-items-center">
      <label for="voucher" class="mr-2 mb-0">Se hai un voucher inseriscilo qui</label>
      <input type="text" id="voucher" placeholder="Voucher" class="form-control" v-model="voucher"/>
      <button class="btn btn-primary h-100" @click="apply()">Applica</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "voucher",
  props: {
    cart: Object,
  },
  data() {
    return {
      voucher: "",
      voucherData: {},
    }
  },
  mounted() {
    this.voucher = this.cart.voucher || "";
    if (this.cart.voucher) {
      this.apply();
    }
  },
  methods: {
    apply() {
      axios.post('/api/v1/cart/apply-voucher', {
        voucher: this.voucher,
      }).then(response => {
        this.voucherData = response.data.data[0];
        this.$emit('voucherApplied', this.voucherData.valore_voucher);
      }).catch(error => {
        this.voucher = "";
        CustomSwal({
          title: "Errore",
          html: error.response.data.errors.join("<br><br>"),
          confirmButtonText: "Ok",
          icon: "error",
        }).then(() => {});
      })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  }
}
</script>

<style scoped>
#voucher {
  max-width: 200px;
}
</style>